import * as Api from '@/api/index/index';
import { switchCategory } from '@/utils/utils';
import NoContent from '@/components/NoContent/NoContent.vue';
import PapaPagination from '@/components/Pagination/Pagination';

export default {
    data() {
        return {
            articleSorts: [],
            article_type_id: '',
            category_id: '',
            ieltsArticles: [],
            pagination: {},
            query: {
                page: '',
            },
            noContentData: {
                pic: 'course_pic',
                tip: '暂时没有文章~',
            },
        };
    },
    components: {
        NoContent,
        PapaPagination,
    },
    mounted() {
        this.getArticleParameters();
        this.category_id = switchCategory(this.$route.path);
        if(this.$route.query.source){
            this.article_type_id = Number(this.$route.query.source);
        }
    },
    methods: {
        getArticleParameters() {
            Api.articleSorts('', (res) => {
                res.forEach((ele) => {
                    if (ele.title === '文章类型') {
                        this.articleSorts = ele.content;
                    }
                });
                this.getIeltsArticle();
            });
        },
        turnToSorts(item, index) {
            this.article_type_id = item.key;
            this.getIeltsArticle();
        },
        getIeltsArticle(page = 1) {
            let params = {
                category_id: this.category_id,
                page: page,
                article_type_id: this.article_type_id != 0 ? this.article_type_id : '',
            };
            Api.articleParameter(params, (res, pagination) => {
                console.log(res);
                this.ieltsArticles = res;
                this.pagination = pagination;
            });
        },
        handleCurrentChange(page) {
            this.getIeltsArticle(page);
        },
        turnToDetail(item) {
            this.$router.push({
                name: 'articleDetail',
                params: {
                    id: item.id,
                },
            });
        },
        handlePagination(pagination) {
            this.getIeltsArticle(pagination);
        },
    },
};
