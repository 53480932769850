<template>
	<div class="quanlity-article">
		<div class="tab">
			<ul>
				<li
					v-for="(item, index) in articleSorts"
					:key="item.key"
					:class="{ active: article_type_id === item.key }"
					@click="turnToSorts(item, index)"
				>
					{{ item.value }}
				</li>
			</ul>
		</div>
		<div class="content" v-if="ieltsArticles.length > 0">
			<dl v-for="item in ieltsArticles" :key="item.id">
				<dt>
					<img
						:src="item.cover_image_url"
						@click="turnToDetail(item)"
						alt=""
					/>
				</dt>
				<dd>
					<h5 @click="turnToDetail(item)">{{ item.title }}</h5>
					<p @click="turnToDetail(item)">{{ item.description }}</p>
				</dd>
			</dl>
		</div>
		<NoContent v-else :data="noContentData"></NoContent>
		<div class="page">
			<papa-pagination
				:pagination="pagination"
				@handlePagination="handlePagination"
			></papa-pagination>
			<!--			<el-pagination-->
			<!--				background-->
			<!--				v-if="pagination.last_page>1"-->
			<!--				@current-change="handleCurrentChange"-->
			<!--				:current-page="currentPage"-->
			<!--				:page-size="15"-->
			<!--				layout="total, prev, pager, next, jumper"-->
			<!--				:total="pagination.total"-->
			<!--			></el-pagination>-->
		</div>
	</div>
</template>
<script>
import IndexJs from './CategoryArticles.js';

export default IndexJs;
</script>
<style lang="less">
.quanlity-article {
	background-color: #fff;
	.tab {
		width: 1200px;
		margin: 0 auto;
		ul {
			padding: 0;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			li {
				cursor: pointer;
				font-size: 16px;
				box-sizing: border-box;
				width: 12.5%;
				height: 36px;
				list-style-type: none;
				border: 1px solid #f6f6f6;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 5px 10.5px;
			}
			li:hover {
				border: 1px solid #29d087;
				background: rgba(245, 255, 249, 1);
				color: #29d087;
			}
			.active {
				border: 1px solid #29d087;
				background: rgba(245, 255, 249, 1);
				color: #29d087;
			}
		}
	}
	.content {
		width: 1200px;
		margin: 0 auto;
		dl {
			border-bottom: 1px solid #ccc;
			text-align: left;
			display: flex;
			padding: 10px 0 10px;
			height: 120px;
			dt {
				display: flex;
				align-items: center;
				height: 120px;
				img {
					cursor: pointer;
					width: 90px;
					height: 90px;
				}
			}
			dd {
				//width: 640px;
				width: 900px;
				h5 {
					font-size: 17px;
					font-weight: 600;
					line-height: 50px;
					margin: 0;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					cursor: pointer;
				}
				p {
					font-size: 14px;
					margin: 0;
					word-break: break-all;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 5;
					overflow: hidden;
					cursor: pointer;
				}
			}
		}
	}
	.page {
		width: 1200px;
		margin: 0 auto;
		.el-pagination {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 10px;
		}
	}
}
</style>
