<template>
    <div class="table_pagination"
         v-if="pagination.prev||pagination.next"
    >
        <div class="pagination">
            <el-button size="mini" :disabled="prevButtonStatus" @click="goFirst">首页</el-button>
            <el-button type="primary" size="mini" :disabled="prevButtonStatus" @click="prevClick"
                       icon="el-icon-arrow-left">上一页
            </el-button>
            <button class="pagination_current_page">{{ pagination.current_page }}</button>
            <el-button type="primary" :disabled="nextButtonStatus" size="mini" @click="nextClick">下一页<i
                class="el-icon-arrow-right el-icon--right"></i></el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PapaPagination',
    props: {
        pagination: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    computed: {
        prevButtonStatus () {
            return this.pagination.current_page < 2
        },
        nextButtonStatus () {
            return Boolean(!this.pagination.next)
        }
    },
    methods: {
        goFirst () {
            let page = 1
            this.$emit('handlePagination', page)
            window.scrollTo(0, 0)
        },
        prevClick () {
            let current = this.pagination.current_page
            let page = current > 1 ? current - 1 : current
            this.$emit('handlePagination', page)
            window.scrollTo(0, 0)

        },
        nextClick () {
            let current = this.pagination.current_page
            let page = current > 0 ? current + 1 : current
            this.$emit('handlePagination', page)
            window.scrollTo(0, 0)

        },
    }
}
</script>

<style lang="less" scoped>

.table_pagination {
    height: 47px;

    .pagination {
        display: flex;
        float: right;

        .pagination_current_page {
            margin: 0 10px;
            width: 2em;
        }
    }
}

</style>
